"use client";

import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import { type ReactNode, useEffect, useState } from "react";
import { Heading } from "../base/heading";
import { HorizontalSpacer } from "../layout/horizontal-spacer";
import { Text } from "./text";
import "@splidejs/react-splide/css";
import { getMediaQuery } from "@/contexts/media-context";
import { lockBodyWhenModalIsOpen } from "@/utils/modal-utils";
import { classNames } from "@/utils/styling-utils";
import { Dialog } from "../base/dialog";
import { ArrowRightIcon } from "../icons/icons";

interface SwiperProps {
  fullWidth?: boolean;
  heading?: string;
  isSidebar?: boolean;
  items: ReactNode[];
  itemsPerPageMedium?: number /* itemsPerPageMedium gjelder for medium skjermstørrelse (600px-768px) */;
  itemsPerPageLarge?: number /* itemsPerPageLarge gjelder for large skjermstørrelse (768px og større) */;
  itemsPerPageXLarge?: number /* itemsPerPageXLarge gjelder for large skjermstørrelse (1024px og større) */;
  notInfinite?: boolean;
  showArrowsAlways?: boolean;
  showArrowsOnTop?: boolean;
  text?: string;
  isImageGallery?: boolean;
}

export const Swiper = ({
  fullWidth,
  heading,
  isSidebar,
  isImageGallery = false,
  items,
  itemsPerPageMedium,
  itemsPerPageLarge,
  itemsPerPageXLarge,
  notInfinite,
  showArrowsAlways,
  showArrowsOnTop,
  text,
}: SwiperProps) => {
  // Definerer standard antall elementer per skjermstørrelse
  const defaultNumberOfItems = 3;
  const itemsPerPageSmallScreen = 1;
  const itemsPerPageMediumScreen = itemsPerPageMedium ?? 2;
  const itemsPerPageLargeScreen = isSidebar ? 1 : (itemsPerPageLarge ?? defaultNumberOfItems); // sidebar blir først synlig på stor skjerm
  const itemsPerPageXLargeScreen = isSidebar ? 1 : (itemsPerPageXLarge ?? defaultNumberOfItems);

  const [selectedSlide, setSelectedSlide] = useState<number | null>(null);
  const [modalOpen, setModalOpen] = useState(false);

  // Åpner modal ved klikk på slide dersom skjermvidden er større enn md
  // og swiperen er ImageGallery
  const handleSlideClick = (index: number) => {
    if (window.matchMedia(getMediaQuery("md")).matches && isImageGallery) {
      setSelectedSlide(index);
      setModalOpen(true);
      lockBodyWhenModalIsOpen(true);
    }
  };

  //lukker modalen med escape knappen
  useEffect(() => {
    const handleKeyDown = (event: WindowEventMap["keydown"]) => {
      if (event.key === "Escape") {
        event.preventDefault();
        setModalOpen(false);
        lockBodyWhenModalIsOpen(false);
      }
    };

    if (modalOpen) {
      document.addEventListener("keydown", handleKeyDown as EventListener);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown as EventListener);
    };
  }, [modalOpen]);

  //lukker modal dersom man klikker utenfor bilde eller knapp
  const handleOutsideClick = (event: MouseEvent) => {
    const targetElement = event.target as HTMLElement;
    const closestImageOrButton = targetElement.closest("img, button");

    if (!closestImageOrButton) {
      lockBodyWhenModalIsOpen(false);
      setModalOpen(false);
    }
  };
  function useOutsideClick(callback: (event: MouseEvent) => void) {
    useEffect(() => {
      //sjekker om det blir klikket
      const handleClick = (event: MouseEvent) => {
        callback(event);
      };

      document.addEventListener("mousedown", handleClick);

      return () => {
        document.removeEventListener("mousedown", handleClick);
      };
    }, [callback]);

    return null;
  }

  //sjekker om det blir klikket
  const refModal = useOutsideClick((event: MouseEvent) => {
    //dersom modalen er åpen og det blir klikket utenfor bilde eller knapp vil modalen lukkes
    if (modalOpen) {
      handleOutsideClick(event);
    }
  });
  return (
    <>
      {heading && <Heading type="h2">{heading}</Heading>}
      {text && (
        <>
          <Text value={text} size="large" />
          <HorizontalSpacer size="medium" />
        </>
      )}
      <Splide
        aria-label="Slider"
        className={classNames("swiper", fullWidth && "swiper--full-width", isSidebar && "swiper--sidebar")}
        hasTrack={false}
        options={{
          arrows: showArrowsAlways ? showArrowsAlways : isSidebar ? false : items.length > defaultNumberOfItems,
          breakpoints: {
            // Breakpoints her må match dem i variables.module.scss > NB: media-query her er max-width, og ikke min-width, som vi har ellers
            // Her må dem ligge i rekkefølge størst til minst
            1280: {
              arrows: showArrowsAlways ? showArrowsAlways : false,
              pagination: isSidebar ? items.length > 1 : items.length > defaultNumberOfItems,
              perPage: itemsPerPageXLargeScreen,
            },
            1024: {
              arrows: showArrowsAlways ? showArrowsAlways : false,
              pagination: isSidebar ? items.length > 1 : items.length > defaultNumberOfItems,
              perPage: itemsPerPageLargeScreen,
            },
            768: {
              arrows: showArrowsAlways ? showArrowsAlways : false,
              pagination: items.length > 2,
              perPage: itemsPerPageMediumScreen,
            },
            600: {
              arrows: showArrowsAlways ? showArrowsAlways : false,
              pagination: items.length > 1,
              perPage: itemsPerPageSmallScreen,
            },
          },
          easing: "linear",
          gap: "2.4rem", // tilsvarer $left-and-right-inset
          lazyLoad: "nearby",
          pagination: !!isSidebar,
          paginationKeyboard: true,
          perPage: itemsPerPageXLargeScreen,
          role: "",
          type: notInfinite || isImageGallery ? "slide" : "loop",
          width: "100%",
          flickMaxPages: 1,
          perMove: 1,
        }}
        role="slider"
        tag="section"
      >
        <div className="splide__arrows">
          <button
            type="button"
            aria-label="Scroll til venstre"
            className={classNames(
              "splide__arrow splide__arrow--prev swiper__arrow-button swiper__arrow-button--prev",
              showArrowsOnTop && "swiper__arrow-button--on-top",
            )}
          >
            <ArrowRightIcon size="large" />
          </button>
          <button
            type="button"
            aria-label="Scroll til høyre"
            className={classNames(
              "splide__arrow splide__arrow--next swiper__arrow-button swiper__arrow-button--next",
              showArrowsOnTop && "swiper__arrow-button--on-top",
            )}
          >
            <ArrowRightIcon size="large" />
          </button>
        </div>
        <SplideTrack>
          {items.map((item, index) => (
            <SplideSlide key={index} onClick={() => handleSlideClick(index)}>
              {item}
            </SplideSlide>
          ))}
        </SplideTrack>
      </Splide>
      <Dialog isOpen={modalOpen} id={"swiper-dialog"} placement="center" width="auto">
        <div className="modal" ref={refModal}>
          {selectedSlide !== null && (
            <Splide
              aria-label="Image Gallery"
              className="modal__swiper"
              hasTrack={false}
              options={{
                type: "loop",
                width: "100%",
                perPage: 1,
                perMove: 1,
                arrows: true,
                paginationKeyboard: true,
                speed: 1000,
                easing: "ease",
                keyboard: "global",
                start: selectedSlide,
              }}
            >
              <SplideTrack>
                {items.map((item, index) => (
                  <SplideSlide key={index}>{item}</SplideSlide>
                ))}
              </SplideTrack>
              <div className="splide__arrows">
                <button
                  type="button"
                  aria-label="Scroll til venstre"
                  className="splide__arrow splide__arrow--prev swiper__arrow-button swiper__arrow-button--prev"
                >
                  <ArrowRightIcon size="large" />
                </button>
                <button
                  type="button"
                  aria-label="Scroll til høyre"
                  className="splide__arrow splide__arrow--next swiper__arrow-button swiper__arrow-button--next"
                >
                  <ArrowRightIcon size="large" />
                </button>
              </div>
            </Splide>
          )}
        </div>
      </Dialog>

      {!isSidebar && <HorizontalSpacer size="large" />}
    </>
  );
};
