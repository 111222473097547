"use client";
import { VerticalContainer } from "@/components/layout/vertical-container";
import { EventCard } from "@/components/ui/event-card";
import { Text } from "@/components/ui/text";
import type { TextTheme } from "@/components/ui/ui.model";
import { UserContext } from "@/contexts/user-context";
import {
  type BookableEventFragment,
  type FilterInput,
  type ImageFragment,
  useBookableEventsSuspenseQuery,
} from "@/generated/client.generated";
import dayjs from "dayjs";
import { parseAsString, useQueryState } from "nuqs";
import React, { useContext } from "react";

interface BookingNextEventsResultsProps {
  currentFilters: ReadonlyArray<FilterInput>;
  take: number;
  textTheme: TextTheme;
}

export function BookingNextEventsResults({ currentFilters, take, textTheme }: BookingNextEventsResultsProps) {
  const [bookingIdToFilter] = useQueryState("bookingId", parseAsString);
  const filters = [...currentFilters];

  const { data: bookableEventsData, error } = useBookableEventsSuspenseQuery({
    variables: {
      filter: filters,
      skip: 0,
      take: take,
    },
  });
  const { myBookedEvents } = useContext(UserContext);

  if (error) {
    throw error;
  }

  if (!bookableEventsData || bookableEventsData.BookableEvents.length === 0) {
    return (
      <VerticalContainer horizontalPlacement="center">
        <Text style="italic" value="Ingen kommende arrangement" color={textTheme} />
      </VerticalContainer>
    );
  }

  const bookableEvents = [...bookableEventsData.BookableEvents].sort(
    (a: BookableEventFragment, b: BookableEventFragment) =>
      dayjs(a.duration.start).isBefore(dayjs(b.duration.start)) ? -1 : 1,
  );

  // Filter out the event matching the bookingId.
  const filteredEvents = bookableEvents.filter(
    (bookableEvent) => !bookingIdToFilter || bookableEvent.id !== bookingIdToFilter,
  );

  if (filteredEvents.length === 0) {
    return (
      <VerticalContainer horizontalPlacement="center">
        <Text style="italic" value="Ingen kommende arrangement" color={textTheme} />
      </VerticalContainer>
    );
  }

  return (
    <div className="booking-next-events-results">
      {filteredEvents.flatMap((bookableEvent) => {
        if (!(bookableEvent.event && bookableEvent.center)) {
          return [];
        }
        return (
          <React.Fragment key={bookableEvent.id}>
            <EventCard
              notification={bookableEvent.notification ?? ""}
              bookableEventId={bookableEvent.id}
              centerId={bookableEvent.center.id}
              centerName={bookableEvent.center.page.title}
              eventName={bookableEvent.event.page.title}
              eventStart={dayjs(bookableEvent.duration.start)}
              eventEnd={dayjs(bookableEvent.duration.end)}
              eventSlug={bookableEvent.event.slug}
              thumbnailImage={(bookableEvent.event.page.thumbnailImage as ImageFragment) ?? undefined}
              status={
                myBookedEvents.find((myBookedEvent) => myBookedEvent.bookableEvent?.id === bookableEvent.id)
                  ? "Booked"
                  : "Bookable"
              }
            />
          </React.Fragment>
        );
      })}
    </div>
  );
}
