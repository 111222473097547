import type { MediaFragment, ResponsiveMediaFragment } from "@/generated/client.generated";
import { useScrollToElement } from "@/hooks/use-scroll-to-element";
import { classNames } from "@/utils/styling-utils";
import { Heading } from "../base/heading";
import { HorizontalSpacer } from "../layout/horizontal-spacer";
import { LinkButton } from "./link-button";
import { Markdown } from "./markdown";
import { Media } from "./media";
import type { TextTheme } from "./ui.model";

export type FlexCardDirectionOnDesktop = "mediaOnTheLeft" | "mediaOnTheRight";

interface FlexCardProps {
  buttonText?: string;
  directionOnDesktop?: FlexCardDirectionOnDesktop; // mediaOnTheLeft er default
  fallbackAlt: string; // will be used if image from Strapi doesn't have an alternative text
  heading?: string;
  /**
   * Set id if you want to use anchor link with scroll-to
   */
  id?: string;
  isSidebar?: boolean;
  media: MediaFragment | ResponsiveMediaFragment;
  priority?: boolean; // specific for Image
  text?: string;
  textTheme?: TextTheme; // dark er default
  url?: string;
  secondButtonText?: string;
  secondUrl?: string;
}

export const FlexCard = ({
  buttonText,
  directionOnDesktop,
  fallbackAlt,
  heading,
  id,
  isSidebar,
  media,
  priority,
  text,
  textTheme,
  url,
  secondButtonText,
  secondUrl,
}: FlexCardProps) => {
  const scrollTo = useScrollToElement(id);

  return (
    <div
      className={classNames(
        "flex-card",
        directionOnDesktop && `flex-card--direction-${directionOnDesktop}`,
        textTheme && `flex-card--text-theme-${textTheme}`,
        isSidebar && "flex-card--sidebar",
      )}
      id={scrollTo ?? ""}
    >
      <div className="flex-card__media">
        <Media fallbackAlt={fallbackAlt} media={media} priority={priority} />
      </div>

      <div className="flex-card__content">
        {heading && <Heading type="h2">{heading}</Heading>}

        {text && <Markdown replaceHeadingLevel1={true}>{text}</Markdown>}

        {url && buttonText && (
          <>
            <HorizontalSpacer />
            <LinkButton buttonType="secondary" href={url}>
              {buttonText}
            </LinkButton>
          </>
        )}

        {secondUrl && secondButtonText && (
          <>
            <HorizontalSpacer />
            <HorizontalSpacer />
            <LinkButton buttonType="secondary" href={secondUrl}>
              {secondButtonText}
            </LinkButton>
          </>
        )}
      </div>
    </div>
  );
};
